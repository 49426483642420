export const conditionalOptions = [
  { label: "Yes", code: true },
  { label: "No", code: false }
];
export const availabilityOptions = [
  { label: "Full Time", code: "Full Time" },
  { label: "Part Time", code: "Part Time" }
];
export const contractOptions = [
  { label: "Permanent", code: "permanent_work" },
  { label: "Temporary", code: "temporary_work" }
];
export const conditionalOptionWithNotSelected = [
  { label: "Yes", code: true },
  { label: "No", code: false },
  { label: "Not Selected", code: 'null' }
];
export const activeOptions = [
  { label: "Active", code: true },
  { label: "Deactivated", code: false },
  { label: "Not Selected", code: "null" },
];
export const isStudentOptions = [
  { label: "Student", code: true },
  { label: "Professional", code: false },
];
export const isProfessional = [
  { label: "Student", code: true },
  { label: "Qualified Healthcare Worker", code: false },
];
export const jobStatusOptions = [
  { label: "Draft Jobs", code: "un-published" },
  { label: "Published Jobs", code: "published" },
];
export const publishedOptions = [
  { label: "Draft", code: 27 },
  { label: "Pending", code: 28 },
  { label: "Published", code: 29 },
  { label: "Inactive", code: 30 },
  { label: "Archive", code: 31 },
];
export const jobAvailabilityOptions = [
  { label: "Full Time", code: "full_time" },
  { label: "Part Time", code: "part_time" }
];
export const isSponsored = [
  { label: "Yes", code: true },
  { label: "No", code: false }
];

export const eligible = [
  { label: "Green", code: "eligible" },
  { label: "Amber", code: "eligible_6_12" },
  { label: "Red", code: "eligible_12_plus" },
  { label: "Blue", code: "eligible_incomplete" }
];
export const fullPartTime = [
  { label: "Full Time", code: "Full Time" },
  { label: "Part Time", code: "Part Time" }
];
