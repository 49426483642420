import candidate from "@/services/candidate";
import job from "@/services/job";
import location from "@/services/location";
import speciality from "@/services/speciality";
import qualification from "@/services/qualification";
import genderService from "@/services/gender";
import filter from "@/services/filter";
import hospital from "@/services/hospital";
import router from "@/router/index";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import _ from "lodash";
import course from "@/services/course";

// TODO:: update the error messages in all export functions
// TODO:: Refactor this to use a common function to populate the data
// TODO:: Replace let with const where ever neccessary
// TODO:: update the function names with generate prifix generate a new function in schema
export const licenseAuthPqr = ({ commit }, payload) => {
  return filter
    .licenseAuthPqr(payload)
    .then(res => {
      let { data } = res;
      return data;
    })
    .catch(err => {
      console.log("error in licenseAuthPqr", err);
      return err;
    });
};

export const genericGetHospitals = ({ commit, getters }, payload) => {
  let { getCustomerId, getCandidateId } = getters;
  if (!getCustomerId) return
  const query = '';
  return job
    .getOrganizationDetails({ customer_uid: getCustomerId, query })
    .then(async res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.organisation.name,
        code: val.organisation_id,
        organisation_type_id: val?.organisation?.organisation_type_id,
      }));
      commit("SET_GENERIC_HOSPITALS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching organization id", err);
      return err;
    });
};

// export const getTitles = ({ commit }) => {
//   return candidate
//     .getTitles()
//     .then(res => {
//       let { data } = res;
//       const mappedData = data.map(val => ({
//         label: val.comments,
//         code: val.location_id
//       }));
//       commit("SET_GENERIC_TITLES", mappedData);
//       return res;
//     })
//     .catch(err => {
//       console.log("error fetching titles", err);
//       return err;
//     });
// };

export const pqrHeaderCountries = ({ commit }) => {
  return filter
    .pqrHeaderCountries()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.country_name,
        code: val.country_id
      }));
      commit("SET_GENERIC_PQR_HEADER_COUNTRIES", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching titles", err);
      return err;
    });
};

export const nonMedicalQualNames = ({ commit, getters }) => {
  const { getCustomerId } = getters;
  let queryPayload = {};
  if (getCustomerId)
    queryPayload = { ...queryPayload, customer_id: getCustomerId }
  const queryString = getFilterQueryStringWithoutArray(queryPayload)
  return candidate
    .getNonMedicalQualNames(queryString)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.name,
        code: val.id
      }));
      commit("SET_NON_MEDICAL_QUALIFICATION_OPTIONS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching non-medical qualifications", err);
      return err;
    });
};

export const scoreForNonMedQualID = ({ commit, getters }, qualification_id) => {
  if (!qualification_id) {
    commit("SET_NON_MEDICAL_QUALIFICATION_SCORES", [])
    return []
  }
  const { getCustomerId } = getters;
  return candidate.getScoreByNonMedicalQualID(qualification_id, getCustomerId)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.score,
        code: val.id
      }));
      commit("SET_NON_MEDICAL_QUALIFICATION_SCORES", mappedData);
      return res.data;
    })
    .catch(err => {
      console.log("error in qualification", err);
      return err;
    });
};

export const pqrHeaderLicensingAuthorities = ({ commit }, query) => {
  return filter
    .pqrHeaderLicensingAuthorities(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.filter(({ licensing }) => licensing === true)
        .map(val => ({
          label: val.issue_authority,
          code: val.issue_authority_id,
        }));
      commit("SET_GENERIC_PQR_HEADER_LICENSING_AUTHORITIES", mappedData);
      return mappedData;
    })
    .catch(err => {
      // commit("SET_GENERIC_PQR_HEADER_LICENSING_AUTHORITIES", []);
      console.log("error fetching titles", err);
      return err;
    });
};

export const pqrHeader = ({ commit }, query) => {
  return filter
    .pqrHeader(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.pqr_name,
        code: val.pqr_header_id,
      }));
      commit("SET_GENERIC_PQR_HEADER", mappedData);
      return mappedData;
    })
    .catch(err => {
      // commit("SET_GENERIC_PQR_HEADER", []);
      console.log("error fetching pqr header", err);
      return err;
    });
};

export const pqrAHPSpeciality = ({ commit }, payload = {}) => {
  const query = getFilterQueryStringWithoutArray(payload)
  return filter
    .pqrAHPSpeciality(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.speciality_name,
        code: val.speciality_id,
      })).sort((a, b) => {
        if (a.label < b.label) { return -1; }
        if (a.label > b.label) { return 1; }
        return 0;
      });
      commit("SET_GENERIC_PQR_AHP_SPECIALITY", mappedData);
      return mappedData;
    })
    .catch(err => {
      console.log("error fetching pqr AHP Speciality", err);
      return err;
    });
}

export const genericGetLocations = ({ commit }) => {
  return candidate
    .getLocations()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.location,
        code: val.location_id
      }));
      commit("SET_GENERIC_LOCATIONS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching locations", err);
      return err;
    });
};

export const getOrganisationBasedLocation = ({ commit, getters }) => {
  const { getCustomerId, getOrgID, getIsParentUser, getRoleScopes } = getters;
  let query = `customer_uid=${getCustomerId}`;
  if (!getIsParentUser && getOrgID) {
    query += `&organisation_id=${getOrgID}`;
  }
  if (!getRoleScopes?.length) {
    const {
      params: { cus_id, org_id },
    } = router.currentRoute;
    query = `customer_uid=${cus_id}`;
    if (org_id) {
      query += `&organisation_id=${org_id}`;
    }
  }
  return candidate
    .getOrganisationBasedLocation(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.location,
        code: val.location_id
      }));
      commit("SET_GENERIC_LOCATIONS", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error fetching locations", err);
      return err;
    });
};

export const getUAELocations = ({ commit }) => {
  return candidate
    .getUAELocations()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_UAE_LOCATIONS", data);
      return res;
    })
    .catch(err => {
      console.log("error fetching getUAELocations", err);
      return err;
    });
};
export const getHospitalName = ({ commit }) => {
  return hospital
    .getHospitalList()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_HOSPITAL_LIST", data);
      return res;
    })
    .catch(err => {
      console.log("error fetching locations", err);
      return err;
    });
};
export const genericGender = ({ commit }) => {
  return genderService
    .gender()
    .then((res) => {
      let { data } = res;
      const mappedData = data.map((val) => ({
        label: val.gender,
        code: val.gender_id,
      }));
      commit(
        "SET_GENERIC_GENDER",
        mappedData.concat({ label: "", code: "null" })
      );
      return res;
    })
    .catch((err) => {
      console.log("error in gender", err);
      return err;
    });
};
export const licensingAuthority = ({ commit }) => {
  return filter
    .licensingAuthority()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.comments,
        code: val.location_id
      }));
      commit("SET_GENERIC_LICENSING_AUTHORITY", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericCandidateLevel = ({ commit, getters }) => {
  const { getUserDetails: { country_id } } = getters;
  let query = '';
  query = `country_id__in=null`;
  // if (country_id)
  //   query = `country_id__in=${country_id}`;
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id,
        is_working_level: val.is_working_level,
        country_id: val?.country_id,
      })).filter(({ label }) => label != "Not Eligible");
      commit("SET_GENERIC_CANDIDATE_LEVEL", mappedData);
      const job_candidate_level = mappedData.filter(({ is_working_level }) => is_working_level);
      commit("SET_GENERIC_CANDIDATE_LEVEL_JOB", job_candidate_level);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};

export const pqrLicensingLevel = ({ commit }, payload) => {
  if (payload?.country_id__in || payload?.country_id__in?.length) {
    const id = payload?.country_id__in[0] || payload?.country_id__in;
    if (id == 181 || id == 149) { //UAE or Saudi
      payload.is_licensing_level = true
    }
    else {
      payload.is_working_level = true
    }
  }
  const query = getFilterQueryStringWithoutArray(payload)
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_PQR_HEADER_LICENSING_LEVEL", mappedData);
      return mappedData;
    })
    .catch(err => {

      console.log("error fetching levels", err);
      return err;
    });
};

export const pqrWorkingLevel = ({ commit }, payload) => {
  if (payload?.country_id__in || payload?.country_id__in?.length) {
    const id = payload?.country_id__in[0] || payload?.country_id__in;
    payload.is_working_level = true
  }
  const query = getFilterQueryStringWithoutArray(payload)
  return candidate
    .candidateLevel(query)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_level,
        code: val.level_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_PQR_HEADER_WORKING_LEVEL", mappedData);
      return mappedData;
    })
    .catch(err => {
      console.log("error fetching levels", err);
      return err;
    });
};

export const recruitingCandidateLevel = ({ commit }) => {
  return candidate
    .recruitingCandidateLevel()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_RECRUITING_CANDIDATE_LEVEL", data);
      return res;
    })
    .catch(err => {
      console.log("error in recruitingCandidateLevel", err);
      return err;
    });
};

export const genericSubSpeciality = ({ commit }) => {
  return speciality
    .subSpeciality()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality_name, sub_speciality }) => ({
        label: `${country_code ? `${country_code} | ` : ''}${speciality_name} | ${sub_speciality}`,
        code: speciality_reference,
        speciality,
        sub_speciality,
        country_code
      }));
      commit("SET_GENERIC_SUB_SPECIALITY", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in skill", err);
      return err;
    });
};

export const getGenericSpecialitySearchBasedOnId = ({ commit }, searchQuery = "specialitySearch") => {
  let { query } = router.history.current;
  let queryKey = 'speciality_references';
  let queryData = query[searchQuery];
  if (queryData) {
    queryData = queryData.replace(/]|\[/g, "");
    return filter.specialitySearch(`${queryKey}=${queryData}`).then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, speciality_id, speciality_reference_group }) => ({
        label: `${country_code ? `${country_code} | ` : ''}${speciality} | ${sub_speciality}`,
        code: speciality_reference,
        speciality,
        sub_speciality,
        country_code,
        sub_speciality_id,
        speciality_id,
        id: speciality_reference,
        speciality_reference_group
      }));
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", mappedData);
      return res;
    }).catch(err => {
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", []);
      console.log("error in genericCountrySpecialitySubspeciality", err);
      return err;
    });
  }
}

export const getGenericSpecialitySearchBasedOnCode = ({ commit }, payload) => {
  let { query } = router.history.current;
  let queryKey = 'speciality_references';
  let queryData = payload;
  if (payload.value.length) {
    if (typeof payload?.value[0] === "object") {
      queryData = payload?.value.map(({ code }) => code).join()
    }
    else {
      queryData = payload?.value.join();
    }
    return filter.specialitySearch(`${queryKey}=${queryData}`).then(res => {
      let { data } = res;
      const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, sub_speciality_id, speciality_id, speciality_reference_group }) => {
        const splitArr = speciality_reference.replace(/[()]/g, '').split(",");
        const label = splitArr[0] === "0" ? sub_speciality : speciality
        const r = {
          label: label,
          code: speciality_reference,
          speciality,
          sub_speciality,
          country_code,
          sub_speciality_id,
          speciality_id,
          id: speciality_reference,
          speciality_reference_group
        }
        return r;
      }) || [];
      commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: mappedData });
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", mappedData);
      return res;
    }).catch(err => {
      commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: [] });
      commit("SET_INIT_GENERIC_SPECIALITY_SEARCH", []);
      console.log("error in genericCountrySpecialitySubspeciality", err);
      return err;
    });
  } else {
    commit("UPDATE_SELECTED_FILTER", { name: "specialitySearch", value: [] });
  }
}

export const genericSpecialitySearch = ({ commit }, searchQuery) => {
  return filter.specialitySearch(searchQuery).then(res => {
    let { data } = res;
    const mappedData = data.map(({ speciality_reference, country_code, speciality, sub_speciality, group_by, speciality_id, sub_speciality_id, speciality_reference_group }) => ({
      label: `${country_code ? `${country_code} | ` : ''}${speciality} | ${sub_speciality}`,
      code: speciality_reference,
      speciality,
      sub_speciality,
      country_code,
      group_by,
      id: speciality_reference,
      speciality_id, sub_speciality_id,
      speciality_reference_group
    }));
    commit("SET_GENERIC_SPECIALITY_SEARCH", mappedData);
    commit("SET_SPECIALITY_SUB_SPECIALITY", mappedData);
    return res;
  }).catch(err => {
    commit("SET_GENERIC_SPECIALITY_SEARCH", []);
    console.log("error in genericCountrySpecialitySubspeciality", err);
    return err;
  });
}

export const genericCandidateType = ({ commit, getters }) => {
  return candidate
    .candidateType()
    .then(res => {
      let { data } = res;
      const { getCustomerTypeIDFromAccessToken } = getters
      let mappedData = [];
      const mapFunction = val => ({
        label: val.candidate_type,
        code: val.candidate_type_id
      });
      if (getCustomerTypeIDFromAccessToken === 5) {
        mappedData = data
          .map(mapFunction)
          .filter(v => v.code !== 6 );
      } else {
        mappedData = data.map(mapFunction);
      }
      commit("SET_GENERIC_CANDIDATE_TYPE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};

export const genericStudentType = ({ commit }) => {
  return candidate
    .candidateType()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.candidate_type,
        code: val.candidate_type_id
      })).filter(val => val.code != 6/*Non-medical Candidate Type */);
      commit("SET_GENERIC_STUDENT_TYPE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in studentType", err);
      return err;
    });
};

export const genericCourseTestType = ({ commit }) => {
  return candidate
    .getCourseTestTypes()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(v => ({
        code: v?.course_test_type_id,
        label: v?.course_test_type,
      }));
      commit("SET_GENERIC_COURSE_TEST_TYPE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in course Test Type", err);
      return err;
    });
};

export const genericOrgDepartment = ({ commit, getters }) => {
  const { getOrgIDFromAccessToken } = getters
  let payload = {}
  if (getOrgIDFromAccessToken)
    payload = { ...payload, organisation_id: getOrgIDFromAccessToken }
  const queryString = getFilterQueryStringWithoutArray(payload)
  return course.getOrgDepartments(queryString)
    .then(res => {
      let { data } = res;
      const mappedData = data.map(v => ({
        code: v.organisation_department_id,
        label: v.department.department_name,
        department_id: v.department.department_id
      }));
      commit("SET_GENERIC_ORG_DEPARTMENT", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in org Department", err);
      return err;
    });
};

export const genericCourseLevel = ({ commit }, payload) => {
  const queryPayload = {
    candidate_type_id: payload[0],
    sort_level__lte: 100,
  }
  const query = getFilterQueryStringWithoutArray(queryPayload)
  return job.fetchQualificationClassification({ query })
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val?.qualification_classification,
        code: val?.qualification_classification_id,
      }));
      commit("SET_GENERIC_COURSE_LEVEL", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in org Department", err);
      return err;
    });
};

export const genericCourseStudyMode = ({ commit, getters }) => {
  return course.getTrainingTypes()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(v => ({
        code: v.training_type_id,
        label: v.training_type
      }));
      commit("SET_GENERIC_COURSE_STUDY_MODE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in org Department", err);
      return err;
    });
};

export const genericHealthSector = ({ commit, getters }) => {
  const enum_name = "healthcaresector"
  const query = ""
  return candidate.getEnumLabels({ enum_name, query })
    .then(res => {
      let { data } = res;
      const mappedData = data.map(v => ({
        code: v,
        label: _.startCase(v)
      }));
      commit("SET_GENERIC_HEALTH_SECTOR", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in org Department", err);
      return err;
    });
};
export const jobStatus = ({ commit }) => {
  return job
    .jobStatus()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_JOB_STATUS", data);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
// export const locationLanguage = ({ commit }) => {
//   return location
//     .locationLanguage()
//     .then(res => {
//       let { data } = res;
//       commit("SET_GENERIC_LOCATION_LANGUAGE", data);
//       return res;
//     })
//     .catch(err => {
//       console.log("error in candidateType", err);
//       return err;
//     });
// };
export const genericLocationLanguage = ({ commit }) => {
  return location
    .locationLanguage()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.language,
        code: val.language_id
      })).sort((a, b) => {
        return a.code === 1 ? -1 : 1;
      });
      commit("SET_GENERIC_LOCATION_LANGUAGE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericLocationRegion = ({ commit }) => {
  return location
    .locationRegion()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.region_name,
        code: val.region_id,
        job: val.job_only
      })).filter(obj => !obj.job);
      commit("SET_GENERIC_LOCATION_REGION", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericLocationRegionCountryInfo = ({ commit }) => {
  const queryString =  null;
  return location
    .regionCountry(queryString)
    .then(res => {
      let { data } = res;
      const nationalityInfo = _.reduce(data, (result, val) => {
        let label = val.country?.nationality;
        const index = _.findIndex(result, {
          label: val.country?.nationality
        });
        if (index >= 0) {
          label = `${val.country?.nationality}(${val.country?.country_name})`;
          result[index]['label'] = `${result[index]['label']}(${result[index]['country_name']})`
        }
        result.push({
          label,
          code: val.country_id,
          country_name: val.country?.country_name,
        })
        return result;
      }, [])
      const birthPlacesInfo = data.map(val => ({
        label: val.country?.country_name,
        code: val.country_id
      }))
      const preferredLocationsInfo = data.map(val => ({
        label: val.country?.country_name,
        code: val.country_id,
        typeId: val.region_id
      }));
      preferredLocationsInfo.unshift({ code: 'all', label: 'All', typeId: 'all'});
      commit("SET_GENERIC_LOCATION_COUNTRY_INFO", data);
      commit("SET_GENERIC_BIRTH_PLACE", birthPlacesInfo);
      commit("SET_GENERIC_NATIONALITY_INFO", nationalityInfo);
      commit("SET_GENERIC_PREFERRED_LOCATIONS", preferredLocationsInfo);
      commit("SET_GENERIC_CLASSIFICATION_LOCATIONS", preferredLocationsInfo);
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
export const genericGetSpeciality = ({ commit }) => {
  return speciality
    .speciality()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        label: val.speciality,
        code: val.speciality_id,
        typeId: val.candidate_type_id
      }));
      commit("SET_GENERIC_SPECIALITY", mappedData);
      commit("SET_GENERIC_AHP_SPECIALITY", mappedData.filter(({ typeId }) => typeId === 3))
      return res;
    })
    .catch(err => {
      console.log("error in candidateType", err);
      return err;
    });
};
// export const skill = ({ commit }) => {
//   return speciality
//     .skill()
//     .then(res => {
//       let { data } = res;
//       commit("SET_GENERIC_SKILL", data);
//       return res;
//     })
//     .catch(err => {
//       console.log("error in skill", err);
//       return err;
//     });
// };
// export const specialInterest = ({ commit }) => {
//   return speciality
//     .specialInterest()
//     .then(res => {
//       let { data } = res;
//       commit("SET_GENERIC_SPECIAL_INTEREST", data);
//       return res;
//     })
//     .catch(err => {
//       console.log("error in skill", err);
//       return err;
//     });
// };
export const getQualification = ({ commit }) => {
  return qualification
    .qualification()
    .then(res => {
      let { data } = res;
      commit("SET_GENERIC_QUALIFICATION", data);
      return res;
    })
    .catch(err => {
      console.log("error in skill", err);
      return err;
    });
};

export const fetchFavouriteFilter = ({ commit, getters }, page_name) => {
  const { getUserId: userId } = getters;
  commit("RESET_FAVOURITE_FILTER");
  return filter
    .getFavouriteFilter({ userId, page_name })
    .then(res => {
      let { data } = res;
      commit("SET_FAVOURITE_FILTER", data);
      return res;
    })
    .catch(err => {
      console.log("error in filter", err);
      return err;
    });
}

export const genericNHSBands = ({ commit }) => {
  return candidate
    .fetchCandidateBands()
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        code: val.band_id,
        label: val.band_name,
      }))
      commit("SET_GENERIC_NHS_BAND", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in filter", err);
      return err;
    });
}

export const getQualificationClassificationLevel = ({ commit }, payload) => {
  const { country_id, candidate_type_id } = payload;
  let queryData = {};
  if (candidate_type_id)
    queryData = { ...queryData, candidate_type_id }
  if (country_id && candidate_type_id != 2)
    queryData = { ...queryData, country_id }
  const queryString = getFilterQueryStringWithoutArray(queryData);
  return filter
    .qualificationClassification({ queryString })
    .then(res => {
      let { data } = res;
      const mappedData = data.map(val => ({
        code: val.qualification_classification_id,
        label: val.qualification_classification,
      }))
      commit("SET_GENERIC_QUALIFICATION_CLASSIFICATION_LEVEL", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in filter", err);
      return err;
    });
};

export const genericCourseTitle = ({ commit, getters }) => {
  const { getOrgID } = getters
  let queryPayload = { all_rows: true, fetch_row_count: false, getOrgID }
  const queryString = getFilterQueryStringWithoutArray(queryPayload)
  return course
    .getCourses({ queryString })
    .then(res => {
      let { data } = res;
      let mappedData = data
        .filter(v => v.organisation_id === getOrgID)
        .map(course => ({
          code: course.training_course_id,
          label: course.course_title
        }));
      mappedData.unshift({ code: 'all', label: 'All Courses' });
      commit("SET_GENERIC_COURSE_TITLE", mappedData);
      return res;
    })
    .catch(err => {
      console.log("error in courseTitle", err);
      return err;
    });
};