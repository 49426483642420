import tabFilter from '@/services/tabFilter';
import { getFilterQueryStringWithoutArray, Role } from "@/helpers/helper.js";

const getDefaultState = () => {
    return {
        jobCandidateTabs: [],
        jobCandidateCount: {},
        jobFilterOptions: [],
        jobSubFilterOptions: [],
        tabStatusSubStatus: [],
        tab_info: {
            tab_id: null,
            filter_id: null,
            sub_filter_id: null,
            page: null,
        },
        tabFilterCandidateCount: null,
        updateToastVisiable: {
            isShow: false,
            msg: null,
            cssClass: null,
            autohide: 5000,
        },
        candidateInformationStep: 0,
        interviewSchedule: [],
        candidateJoiningDate: null,
    }
}
const state = getDefaultState();

const getters = {
    candidateInformationStep: (state) => state.candidateInformationStep,
    jobCandidateTabs: (state) => state.jobCandidateTabs,
    getJobCandidateCount: (state) => state.jobCandidateCount,
    getJobCandidateTabs: (state) => state.jobCandidateTabs.length ? state.jobCandidateTabs.map(({ tab_ }) => { return { tab_id: tab_?.id, tab_name: tab_?.tab_name } }) : [],
    getJobFilterOptions: (state) => state.jobFilterOptions.length ? state.jobFilterOptions.map(({ filter_ }) => { return { code: filter_?.id, label: filter_?.filter_name } }) : [],
    getJobSubFilterOptions: (state) => state.jobSubFilterOptions.length ? state.jobSubFilterOptions.map(({ sub_filter_ }) => { return { code: sub_filter_?.id, label: sub_filter_?.filter_name } }) : [],
    getDisplayStatusSubStatus: (state) => state.tabStatusSubStatus,
    getTabInfo: (state) => state.tab_info,
    getTabFilterCandidateCount: (state) => state.tabFilterCandidateCount,
    getUpdateToast: (state) => state.updateToastVisiable,
    getInterviewSchedule: (state) => state.interviewSchedule,
    getCandidateJoiningDate: (state) => state.candidateJoiningDate,
}

const mutations = {
    ['SET_CANDIDATE_INFORMATION_STEP'](state, data) {
        state.candidateInformationStep = data;
    },
    ['SET_JOB_CANDIDATE_TABS'](state, data) {
        state.jobCandidateTabs = data;
    },
    ['RESET_JOB_CANDIDATE_TABS'](state) {
        state.jobCandidateTabs = [];
    },
    ['SET_JOB_CANDIDATE_COUNT'](state, data) {
        state.jobCandidateCount = { ...state.jobCandidateCount, ...data };
    },
    ['RESET_JOB_CANDIDATE_COUNT'](state) {
        state.jobCandidateCount = {}
    },
    ['SET_JOB_FILTER_OPTIONS'](state, data) {
        state.jobFilterOptions = data;
    },
    ['SET_JOB_SUB_FILTER_OPTIONS'](state, data) {
        state.jobSubFilterOptions = data;
    },
    ['RESET_JOB_FILTER_OPTIONS'](state) {
        state.jobFilterOptions = [];
    },
    ['RESET_JOB_SUB_FILTER_OPTIONS'](state) {
        state.jobSubFilterOptions = [];
    },
    ['SET_DISPLAY_STATUS_SUB_STATUS'](state, payload) {
        state.tabStatusSubStatus = payload;
    },
    ['RESET_DISPLAY_STATUS_SUB_STATUS'](state) {
        state.tabStatusSubStatus = [];
    },
    ['SET_TAB_INFO'](state, data) {
        state.tab_info = { ...state.tab_info, ...data };
    },
    ['SET_TAB_CANDIDATE_COUNT'](state, payload) {
        state.tabFilterCandidateCount = payload;
    },
    ['RESET_TAB_CANDIDATE_COUNT'](state) {
        state.tabFilterCandidateCount = null;
    },
    ['SET_UPDATE_TOAST_VISIBLE'](state, payload) {
        state.updateToastVisiable = { ...state.updateToastVisiable, ...payload };
        setTimeout(() => {
            state.updateToastVisiable = { ...state.updateToastVisiable, isShow: false, msg: null, cssClass: null };
        }, 5000);
    },
    ["SET_INTERVIEW_SCHEDULE"](state, data) {
        state.interviewSchedule = data;
    },
    ["SET_CANDIDATE_JOINING_DATE"](state, data) {
        state.candidateJoiningDate = data;
    },
    ["RESET_CANDIDATE_JOINING_DATE"](state) {
        state.candidateJoiningDate = null;
    },
}

const actions = {
    fetchJobCandidateTabs({ state, commit, getters }) {
        commit("RESET_JOB_CANDIDATE_TABS");
        const { getRoleScopes } = getters;
        let rolecheck = getRoleScopes.length ? getRoleScopes[0] : '';
        if ([
            Role.customerAdmin,
            Role.customerRecruiter,
            Role.systemAdmin,
            Role.systemRecruiter,
        ].includes(rolecheck)
        ) {
            commit('SET_CANDIDATE_INFORMATION_STEP', 1)
        }
        return tabFilter
            .getJobCandidateTabs()
            .then(res => {
                let { data } = res;
                commit("SET_JOB_CANDIDATE_TABS", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching jobcandidate Tabs", err);
                return err;
            })
    },
    initJobCandidateCount({ state, commit, dispatch, getters }) {
        let { getJobCandidateTabs, getTabInfo: { page }, candidateProfile, getJobDetailsById } = getters;
        let payload = {};
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            payload = { ...payload, candidate_uid };
        }
        if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            payload = { ...payload, job_id };
        }
        commit("RESET_JOB_CANDIDATE_COUNT");
        getJobCandidateTabs.forEach(async ({ tab_id }) => {
            await dispatch("fetchJobCandidateCount", { ...payload, tab_id });
        })
    },
    fetchJobCandidateCount({ state, commit, dispatch, getters }, payload) {
        const { getChildOrgWithCustomerQuery } = getters;
        const { tab_id, job_id, candidate_uid } = payload;
        let payloadData = {}
        if (job_id)
            payloadData = { ...payloadData, job_id };
        if (candidate_uid)
            payloadData = { ...payloadData, candidate_uid };
        let queryString = getFilterQueryStringWithoutArray(payloadData);
        queryString += `&${getChildOrgWithCustomerQuery}`
        return tabFilter
            .jobCandidateCount({ tab_id, queryString })
            .then(res => {
                const { data } = res;
                let candidateCount = {};
                candidateCount[tab_id] = data;
                commit("SET_JOB_CANDIDATE_COUNT", candidateCount);
                return res.data;
            })
            .catch(err => {
                console.log("Error while fetch job candidate count", err)
                return err;
            })
    },
    fetchJobFilterOptions({ state, commit, getters }) {
        commit("RESET_JOB_FILTER_OPTIONS");
        commit("RESET_CANDIDATE_LIST_FOR_JOB_ID");
        commit("RESET_DISPLAY_STATUS_SUB_STATUS");
        commit("RESET_JOB_LIST_FOR_CANDIDATE_ID");
        commit("RESET_TAB_CANDIDATE_COUNT");
        const { getTabInfo } = getters;
        return tabFilter
            .getTabFilters(getTabInfo)
            .then(res => {
                let { data } = res;
                commit("SET_JOB_FILTER_OPTIONS", data)
                return data;
            })
            .catch(err => {
                console.log("Error while fetch jobcandidate Filter", err)
                return err;
            })
    },
    fetchJobSubFilterOptions({ state, commit, getters }) {
        commit("RESET_JOB_SUB_FILTER_OPTIONS");
        commit("RESET_SUB_STATUS_OPTIONS");
        const { getTabInfo } = getters;
        if (getTabInfo['tab_id'] && getTabInfo['filter_id'])
            return tabFilter
                .getTabSubFilters(getTabInfo)
                .then(res => {
                    let { data } = res;
                    commit("SET_JOB_SUB_FILTER_OPTIONS", data)
                    return data;
                })
                .catch(err => {
                    console.log("Error while fetch jobcandidate Filter", err)
                    return err;
                })
    },
    fetchCandidatesStatusSubStatus({ state, commit, dispatch, getters }) {
        const { getTabInfo, isJobSupplierFromAccessToken, isCandidateSupplierFromAccessToken } = getters;
        const { tab_id, filter_id, sub_filter_id } = getTabInfo;
        let queryPayload = { user_action: 'display' };
        if (sub_filter_id)
            queryPayload['sub_filter_id'] = sub_filter_id;
        if (tab_id)
            queryPayload = { ...queryPayload, tab_id };
        if (filter_id)
            queryPayload = { ...queryPayload, filter_id__in: filter_id }
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        const finalPayload = { queryString };
        if (tab_id && filter_id) {
            return tabFilter
                .getDisplayStatusSubStatus(finalPayload)
                .then(res => {
                    let { data } = res;
                    let status = data
                    if (isJobSupplierFromAccessToken)
                        status = _(data).filter(({ display_status }) => display_status != 75).value()
                    if (isCandidateSupplierFromAccessToken)
                        status = _(data).filter(({ display_status }) => display_status != 67).value()
                    commit("SET_DISPLAY_STATUS_SUB_STATUS", status);
                    return data;
                })
                .catch(err => {
                    console.log('Error while fetch status sub status', err);
                    return err;
                })
        }
        return
    },
    setTabInfo({ state, commit, dispatch, getters }, payload) {
        commit("SET_TAB_INFO", payload);
        return
    },
    fetchTabFilterCandidateCount({ state, commit, dispatch, getters }) {
        const {
            getChildOrgWithCustomerQuery,
            getJobDetailsById,
            getTabInfo: {
                tab_id,
                filter_id,
                sub_filter_id,
                page,
                reference_type_id,
                job_group_ids
            },
            candidateProfile
        } = getters;
        let queryPayload = {}
        if (sub_filter_id)
            queryPayload = { ...queryPayload, sub_filter_id };
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            queryPayload = { ...queryPayload, candidate_uid };
        } else if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            queryPayload = { ...queryPayload, job_id };
        }
        if ([1, 3].includes(filter_id)) {
            queryPayload['job_applied'] = filter_id === 1;
        }
        if (reference_type_id) {
            queryPayload = { ...queryPayload, reference_type_id }
        }
        if (tab_id)
            queryPayload = { ...queryPayload, tab_id };
        if (filter_id)
            queryPayload = { ...queryPayload, filter_id__in: filter_id };
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        if (job_group_ids)
            queryString += `&group_ids__overlap={${job_group_ids.join(',')}}`;
        queryString += `&${getChildOrgWithCustomerQuery}`;
        return tabFilter
            .tabFilterCandidateCount({ queryString })
            .then(res => {
                let { data } = res;
                commit("SET_TAB_CANDIDATE_COUNT", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching TabFilter Candidate Count", err);
                return err;
            })
    },
    fetchFavouriteCount({ state, commit, dispatch, getters }) {
        const { getJobDetailsById, getTabInfo: { page, reference_type_id, job_group_ids }, candidateProfile } = getters;
        let queryPayload = {};
        if (page === "CandidateDetails") {
            const { candidate_uid } = candidateProfile
            queryPayload = { candidate_uid };
        } else if (page === "JobDetails") {
            const { job_id } = getJobDetailsById;
            queryPayload = { job_id };
        }
        if (reference_type_id)
            queryPayload = { ...queryPayload, reference_type_id };
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        if (job_group_ids)
            queryString += `&group_ids__overlap={${job_group_ids.join(',')}}`;
        let payload = [
            {
                "display_status": 42,
                "display_sub_status": 70
            }
        ];
        return tabFilter
            .favouriteCount({ queryString, payload })
            .then(res => {
                let { data } = res;
                commit("SET_TAB_CANDIDATE_COUNT", data);
                return data;
            })
            .catch(err => {
                console.log("Error while fetching Favourite Count", err);
                return err;
            })
    },
    fetchInterviewSchedule: ({ commit, getters }, payload = {}) => {
        const { getUserId: user_id, getIsChildUser, getOrgID: organisation_id, getCustomerId: customer_uid } = getters;
        let queryPayload = !getIsChildUser ? { customer_uid } : { customer_uid, organisation_id };
        // let queryPayload = { customer_uid }
        // if (!getIsChildUser) {
        //     queryPayload = {
        //         ...queryPayload,
        //         organisation_id,
        //     }
        // }
        queryPayload = { ...queryPayload, ...payload }
        let queryString = getFilterQueryStringWithoutArray(queryPayload)
        return tabFilter.getInterviewSchedule({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_INTERVIEW_SCHEDULE", data);
                return data;
            })
            .catch(err => {
                console.log("Error While Fetching Interview Request Action", err);
                return err;
            })
    },
    fetchCandidateJoiningDate: ({ commit, getters }, payload = {}) => {
        commit("RESET_CANDIDATE_JOINING_DATE")
        const { getIsChildUser, getOrgID: organisation_id, getCustomerId: customer_uid } = getters;
        let queryPayload = !getIsChildUser ? { customer_uid } : { customer_uid, organisation_id };
        queryPayload = { ...queryPayload, ...payload }
        let queryString = getFilterQueryStringWithoutArray(queryPayload)
        return tabFilter.getInterviewSchedule({ queryString })
            .then(res => {
                const { data } = res;
                commit("SET_CANDIDATE_JOINING_DATE", data.length ? data[0] : null);
                return res;
            })
            .catch(err => {
                console.log("Error While Fetching Candidate Joining Date", err);
                return err;
            })
    },
    fetchInterviewCandidateCount({ commit, dispatch, getters }, payload = {}) {
        const queryString = getFilterQueryStringWithoutArray(payload);
        return tabFilter
            .tabFilterCandidateCount({ queryString })
            .then(res => {
                return res;
            })
            .catch(err => {
                console.log("Error while fetching TabFilter Candidate Count", err);
                return err;
            })
    }
};

export default { state, getters, mutations, actions };